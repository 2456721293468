<template>
  <div class="fill-code-container" v-if="user && !user.actived">
    <div class="fill-code-bg"></div>
    <div
      class="fiil-code__main-body"
      :class="{
        ok: codeOK,
      }"
    >
      <template v-if="!requesting && qrValue">
        <div
          class="info__container"
          :class="{
            'without-user': !(codeOK && !codeUsed),
          }"
        >
          <template v-if="codeOK && !codeUsed">
            <div class="inviter__info">
              <template v-if="inviter">
                <p class="pic"><img :src="inviter.headimgurl" alt="" /></p>
                <p class="name">{{ inviter.nickname }}</p>
              </template>
            </div>
          </template>
          <p class="ok__hint">
            {{
              codeOK
                ? codeUsed
                  ? "邀请码失效，请等待～"
                  : "邀请你成为时光好友！"
                : "暂无邀请码，请等待～"
            }}
          </p>
        </div>
      </template>
      <p class="loading__hint" v-else>请求中...</p>
      <div v-if="qrValue" class="qr__container">
        <qrcode class="qr__box" :value="qrValue" ref="qrcode" style="display: none"></qrcode>
        <img v-if="!user.actived" class="qr__box" :src="imgSrc" alt="" />
        <p class="hint">
          {{
            codeOK
              ? codeUsed
                ? "关注公众号 成为好友"
                : "关注公众号 敬请期待"
              : "关注公众号 敬请期待"
          }}
        </p>
      </div>
      <div class="bottom-text">
        <img src="@/assets/images/lbg-2.png" />
        <p>时光·亲友工具</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import qrcode from 'qrcode.vue';

export default {
  name: 'view-invite',
  props: ['code'],
  components: {
    qrcode,
  },
  data() {
    return {
      requesting: false,
      codeOK: false,
      codeUsed: false,
      qrValue: null,
      expireSeconds: null,
      inviter: null,
      imgSrc: null,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user: {
      handler(val) {
        if (val) {
          this.getQrcode();
        }

        if (val) {
          if (val.actived) {
            // this.goPersonProfile();
          } else {
            this.getQrcode();
          }
        }
      },
      immediate: true,
    },
    qrValue() {
      this.getImg();
    },
  },
  mounted() {
    // this.getQrcode();
  },
  methods: {
    goPersonProfile() {
      if (this.code) {
        this.$request.get(`api/user/code?code=${this.code}`).then((res) => {
          const {
            data: { uid },
          } = res;
          if (uid) {
            this.$router.replace({
              path: `/friend/${uid}`,
            });
          }
        });
      } else {
        this.$router.replace({
          path: '/home',
        });
      }
    },
    getImg() {
      this.$nextTick(() => {
        if (this.$refs.qrcode) {
          const canvas = this.$refs.qrcode.$el.querySelector('canvas');
          this.imgSrc = canvas.toDataURL('image/png');
        }
      });
    },
    getQrcode() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get('api/wechat/qrcode', {
            code: this.code,
            uid: this.user.uid,
          })
          .then((res) => {
            const { data } = res;
            that.codeOK = data.ok;
            that.codeUsed = data.used;
            that.expireSeconds = data.expireSeconds;
            that.qrValue = data.url;
            that.inviter = data.inviter;

            // 如果用户已经激活
            if (that.user.actived) {
              const url = this.code && that.inviter ? `/friend/${that.inviter.uid}` : '/';
              that.$router.replace({
                path: url,
              });
            }
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onSubmit() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .post('api/user/invite/exp-code', that.postData)
          .then((res) => {
            if (res.data === 'SUCCESS') {
              this.$store.commit('SET_USER_ACTIVED');
              this.$router.replace({
                path: '/home',
              });
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
  },
};
</script>

<style></style>
